import envManager from "@/assets/functions/envManager"
import { getFirestore, doc, collection, query, where, addDoc, getDoc, getDocs, updateDoc, setDoc, onSnapshot, deleteDoc } from "firebase/firestore"

const db = getFirestore()
const collectionPrefix = envManager.getPrefix()
const collectionId = "faqs"
const collectionName = collectionPrefix + collectionId

class FAQ{
    constructor(id, question, answer, upvote = [], fav = false){
        this.id = id
        this.question = question
        this.answer = answer
        this.upvote = upvote
        this.fav = fav
    }

    // get
    static async getAll(){
        const collectionRef = collection(db, collectionName)
        const response = await getDocs(collectionRef)

        const faqs = []

        response.forEach(document => {
            const data = document.data()
            const tmp_faq = new FAQ(document.id, data.question, data.answer, data.upvote, data.fav)
            faqs.push(tmp_faq)
        })

        return faqs
    }

    // listen
    static listenAll(callback = null){
        const collectionRef = collection(db, collectionName)
        const unsub = onSnapshot(collectionRef, snapshot => {
            const faqs = []

            snapshot.forEach(document => {
                const data = document.data()
                const tmp_faq = new FAQ(document.id, data.question, data.answer, data.upvote, data.fav)
                faqs.push(tmp_faq)
            })

            if(callback != null){
                callback(faqs)
            }
        })
        return unsub
    }

    // edit db
    async save(){
        const collectionRef = collection(db, collectionName)
        if(this.id == null){
            const response = await addDoc(collectionRef, {
                question: this.question,
                answer: this.answer,
                upvote: this.upvote,
                fav: this.fav
            })
            this.id = response.id
        }else{
            await updateDoc(doc(db, collectionName, this.id), {
                question: this.question,
                answer: this.answer,
                upvote: this.upvote,
                fav: this.fav
            })
        }
    }
    delete(){
        const documentRef = doc(db, collectionName, this.id)
        deleteDoc(documentRef)
    }
}

export default FAQ