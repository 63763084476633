<template>
    <v-container>
        <v-card>
            <v-card-title class="d-flex align-center justify-space-between">
                <span>FAQ :</span>
                <span class="d-flex align-center">
                    <v-checkbox v-model="multiSelect"></v-checkbox>
                    <h6>Sélection multiple</h6>
                </span>
            </v-card-title>
            <v-card-text>
                <v-expansion-panels accordion :multiple="multiSelect">
                    <v-expansion-panel v-for="question in questions">
                        <v-expansion-panel-header>
                            <div class="d-flex align-center">
                                <v-btn icon @click.prevent.stop="toggleFav(question)" v-if="profile.role == 'SuperAdmin'">
                                    <v-icon color="warning" v-if="question.fav">mdi-star</v-icon>
                                    <v-icon color="warning" v-else>mdi-star-outline</v-icon>
                                </v-btn>
                                <v-icon class="pr-2" color="warning" v-else-if="question.fav">mdi-star</v-icon>
                                <div v-text="question.question"></div>
                            </div>
                            <div class="d-flex justify-end align-center" v-if="profile.role == 'SuperAdmin'">
                                <div v-if="question.upvote.length <= 1" v-text="question.upvote.length + ' vote'" class="pr-2"></div>
                                <div v-else v-text="question.upvote.length + ' votes'" class="pr-2"></div>
                                <v-btn icon @click.prevent.stop="openOne(question)">
                                    <v-icon>mdi-pencil-outline</v-icon>
                                </v-btn>
                                <v-btn icon @click.prevent.stop="deleteFAQ(question)">
                                    <v-icon color="error">mdi-delete-outline</v-icon>
                                </v-btn>
                            </div>
                            <div class="d-flex justify-end align-center" v-else>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn v-on="on" icon @click.prevent.stop="toggleUpvote(question)">
                                            <v-icon color="info" v-if="question.upvote.includes(profile.id)">mdi-arrow-up-bold</v-icon>
                                            <v-icon color="info" v-else>mdi-arrow-up-bold-outline</v-icon>
                                        </v-btn>
                                    </template>
                                    <span v-if="question.upvote.includes(profile.id)">Annuler</span>
                                    <span v-else>Utile</span>
                                </v-tooltip>
                            </div>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <div v-text="question.answer"></div>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
                
                <v-col cols="12" class="mt-3 pa-3 d-flex justify-end" v-if="profile.role == 'SuperAdmin'">
                    <v-btn color="primary" fab @click="openNew()">
                        <v-icon>mdi-plus</v-icon>
                    </v-btn>
                </v-col>

                <v-dialog v-model="configModalDisplayed" width="1000" persistent>
                    <v-card>
                        <v-card-title>
                            <span class="text-h5" v-if="configData == null">Nouvelle question</span>
                            <span class="text-h5" v-else>Modifier la question</span>
                        </v-card-title>
                        <v-card-text v-if="configData">
                            <v-row>
                                <v-col cols="12" class="py-2">
                                    <v-text-field label="Question" v-model="configData.question" />
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="py-2">
                                    <v-textarea label="Réponse" v-model="configData.answer" rows="2"></v-textarea>
                                </v-col>
                            </v-row>
                            <v-row class="d-flex align-center justify-center">
                                <v-btn class="mx-3" color="secondary" @click="close">Annuler</v-btn>
                                <v-btn class="mx-3" color="primary" @click="save">Valider</v-btn>
                            </v-row>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-card-text>
        </v-card>
    </v-container>
</template>
<script>
	import Swal from 'sweetalert2/dist/sweetalert2.js'
	import logger from "@/assets/functions/logger"

    import FAQ from "@/classes/FAQ"

    export default {
        name: "faq",
		props: ["user", "profile"],
		data() {
			return {
                questions: [],
				configModalDisplayed: false,
                configData: null,
                multiSelect: false,
                unsub: []
			}
		},
        created() {
            // récupération des questions
            this.unsub.push(FAQ.listenAll((tmp_faq) => {
                const faq = []
                for (const question of tmp_faq) {
                    faq.push(question)
                }

                faq.sort(function (a, b) {
                    if(a.fav && !b.fav) return -1
                    else if(b.fav && !a.fav) return 1
                    else return b.upvote.length -  a.upvote.length
                });

                this.questions = faq
            }))
        },
        methods: {
            openNew(){
                this.configData = {}
                this.configData.question = null
                this.configData.answer = null
                this.configModalDisplayed = true
            },
            openOne(FAQ){
                this.configData = {}
                this.configData.id = FAQ.id
                this.configData.question = FAQ.question
                this.configData.answer = FAQ.answer
                this.configData.faq = FAQ
                this.configModalDisplayed = true
            },
            close(){
                this.configModalDisplayed = false
                this.configData = null
            },
            save(){
                if(this.configData.id){
                    this.configData.faq.question = this.configData.question
                    this.configData.faq.answer = this.configData.answer
                    logger.log(this.profile.id, "UPDATE FAQ", this.profile.email + " a modifié une faq (" + this.configData.faq.question + ")")
                    this.configData.faq.save()
                }else{
                    const newFAQ = new FAQ(null, this.configData.question, this.configData.answer)
                    logger.log(this.profile.id, "ADD FAQ", this.profile.email + " a ajouté une FAQ")
                    newFAQ.save()
                }   

                this.configModalDisplayed = false
                this.configData = null
            },
            deleteFAQ(FAQ){
                Swal.fire({
					title: "Etes-vous sûr ?",
					icon: "question",
					showCancelButton: true,
					confirmButtonText: "Oui",
                    cancelButtonText: "Annuler",
				}).then((result) => {
					if(result.isConfirmed){
                        logger.log(this.profile.id, "DELETE FAQ", this.profile.email + " a supprimé une FAQ (" + FAQ.question + ")")
                        FAQ.delete()
					}
				})
            },
            toggleFav(FAQ){
                if(this.profile.role == 'SuperAdmin'){
                    FAQ.fav = !FAQ.fav
                    if(FAQ.fav) logger.log(this.profile.id, "UPDATE FAQ", this.profile.email + " a ajouté une FAQ aux favoris (" + FAQ.question + ")")
                    else logger.log(this.profile.id, "UPDATE FAQ", this.profile.email + " a retiré une FAQ des favoris (" + FAQ.question + ")")
                    FAQ.save()
                }
            },
            toggleUpvote(FAQ){
                if(FAQ.upvote.includes(this.profile.id)){
                    const index = FAQ.upvote.indexOf(this.profile.id);
                    if (index > -1) {
                        FAQ.upvote.splice(index, 1)
                    }
                    logger.log(this.profile.id, "UPDATE FAQ", this.profile.email + " a downvote une faq (" + FAQ.question + ")")
                    FAQ.save()
                }else{
                    FAQ.upvote.push(this.profile.id)
                    logger.log(this.profile.id, "UPDATE FAQ", this.profile.email + " a upvote une faq (" + FAQ.question + ")")
                    FAQ.save()
                }
            }
        },
        destroy() {
            this.unsub.forEach(unsub => unsub())
        }
    }
</script>